<template>
	<div class="basic">
		<div class="influencerTitle">{{influencer.name}}</div>
		<div
			class="bigScore right"
			:class="{'good ':score.score>=0,'bad ':score.score<0}"
		>{{(score.score > 0 ? "+" :"") + score.score}} ({{score.numCorrect}}/{{score.numChoices}}) {{score.percentage}}%</div>
		<score-graph :scoreLines="scoreLines"></score-graph>

		<div class="divider">Choices Influenced</div>
		<choice-row :choice="choice" v-for="choice in choices" v-bind:key="choice.id"></choice-row>

		<div class="footer">
			<button class="goButton" @click="done()">Back</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ScoreGraph from "@/components/ScoreGraph";
import ChoiceRow from "@/components/ChoiceRow";

export default {
	name: "Influencer",
	components: {
		ChoiceRow,
		ScoreGraph
	},
	data() {
		return {
			influencer: {},
			score: {},
			choices: [],
			scoreLines: []
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.scoreLines = JSON.parse(localStorage.getItem(`influencer-${this.$route.params.id}-score-lines`));
			this.choices = JSON.parse(localStorage.getItem(`influencer-${this.$route.params.id}-choices`));
			this.influencer = await this.$services.getOne('influencers', this.$route.params.id);
			// console.log("Influencer is", this.influencer)
			this.score = await this.$services.get(`score?influencerID=${this.influencer.id}`);
			console.log("Got score", this.score)
			this.loadChoices();
			const allFeeds = await this.$services.get("feeds");
			const scoredFeeds = [];
			let doneFeeds = 0;
			allFeeds.map(async (feed) => {
				feed.score = await this.$services.get(`score?influencerID=${this.influencer.id}&feedID=${feed.id}`);
				const scores = await this.$services.get(`score?influencerID=${this.influencer.id}&feedID=${feed.id}&daily`);
				if (scores.length <= 0) {
					doneFeeds++;
				} else {
					//Get the scores for the trend graph
					scoredFeeds.push({ name: feed.name, color: feed.color, scores: scores });
					doneFeeds++;
				}
				if (doneFeeds == allFeeds.length) {
					this.scoreLines = scoredFeeds;
					localStorage.setItem(`influencer-${this.$route.params.id}-score-lines`, JSON.stringify(this.scoreLines));
				}
			});
		},
		loadChoices: async function () {
			this.choices = await this.$services.get(`choices/filtered?influencerID=${this.influencer.id}`);
			localStorage.setItem(`influencer-${this.$route.params.id}-choices`, JSON.stringify(this.choices));
			console.log("Choices are", this.choices)
		},
		done() {
			this.$router.back();
		}
	}
}

</script>

<style scoped>
.influencerTitle {
	display: inline-block;
	font-size: 24px;
	margin-left: 5px;
}
.bigScore {
	display: inline-block;
	font-size: 22px;
	margin-bottom: 10px;
}
</style>
