<template>
	<svg ref="canvas" width="100%" :height="height ? height : '180px'" />
</template>

<script>
import * as d3 from 'd3';

export default {
	name: "ScoreGraph",
	props: ["scoreLines", "height"],
	data() {
		return {
			width: 0,
			dataLines: []
		};
	},
	watch: {
		scoreLines: function () {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.width = this.$el.clientWidth;
			this.dataLines = await this.addRunningTotals(this.scoreLines);
			this.drawScoreGraph();
		},

		goToDate: async function (dt) {
			const date = new Date(dt.date);
			if (this.$services.hasKey("Driller")) {
				this.$router.push(`/date/${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`)
			}
		},

		drawScoreGraph: async function () {
			let allScores = [];
			this.dataLines.forEach((scoreLine) => {
				allScores = allScores.concat(scoreLine.scores);
			});
			const minDate = d3.min(allScores, score => new Date(score.date));
			const maxDate = d3.max(allScores, score => new Date(score.date));
			const minScore = Math.min(2, d3.min(allScores, score => score.runningScore)) - 3;
			const maxScore = Math.max(-2, d3.max(allScores, score => score.runningScore)) + 3;
			const margin = {top: 20, right: 10, bottom: 10, left: 40};
			const width = this.width - margin.left - margin.right;
			const height = this.height ? (parseInt(this.height) - 17) : 160;
			const x = d3.scaleTime().domain([minDate, maxDate]).range([0, width]);
			const y = d3.scaleLinear().domain([minScore, maxScore]).range([height, 0]);
			const line = d3.line()
				.x(d => x(new Date(d.date)))
				.y(d => y(d.runningScore))
				.curve(d3.curveCatmullRom);
			const chart = d3.select(this.$refs.canvas);

			//Larger clickable areas for the chart Circles
			chart.selectAll()
				.data(allScores).enter()
				.append('circle')
				.attr('r', '8')
				.attr('transform', `translate(${margin.left},0)`)
				.attr('cx', d => x(new Date(d.date)))
				.attr('cy', d => y(d.runningScore))
				.on("click", d => this.goToDate(d));

			// X and Y Axes
			const yAxis = d3.axisLeft(y)
				.tickFormat(d3.format('.2s'));
			chart.append('g')
				.attr("class", "axisWhite")
				.attr('transform', `translate(${margin.left},0)`)
				.call(yAxis);

			const xAxis = d3.axisBottom(x);
			xAxis.tickArguments([7, d3.timeFormat('%-m/%-d')]);
			chart.append('g')
				.attr("class", "axisWhite")
				.attr('transform', `translate(${margin.left},${height})`)
				.call(xAxis);

			//Chart Lines
			chart.selectAll()
				.data(this.scoreLines).enter()
				.append('path')
				.attr('transform', `translate(${margin.left},0)`)
				.attr('fill', 'none')
				.attr('stroke', d => d.color)
				.attr('stroke-width', 3)
				.attr('shape-rendering', 'crispEdges')
				.datum(d => d.scores)
				.attr('d', line)

			//Chart Circles
			chart.selectAll()
				.data(allScores).enter()
				.append('circle')
				.attr('transform', `translate(${margin.left},0)`)
				.attr('r', '2')
				.attr('shape-rendering', 'crispEdges')
				.attr('fill', d => d.score < 0 ? "#e85252" : "#00b300")
				.attr('stroke', "#cccccc")
				.attr('stroke-width', .5)
				.attr('cx', d => x(new Date(d.date)))
				.attr('cy', d => y(d.runningScore))
				.on("click", d => this.goToDate(d));

		},

		addRunningTotals: async function (scoreLines) {
			scoreLines.forEach((scoreLine) => {
				let runningScore = 0, runningChoices = 0, runningCorrect = 0;
				if (scoreLine.scores && scoreLine.scores.length > 0) {
					scoreLine.scores.forEach((score) => {
						runningScore += +score.score;
						runningChoices += +score.numChoices;
						runningCorrect += +score.numCorrect;
						score.runningScore = runningScore;
						score.runningChoices = runningChoices;
						score.runningCorrect = runningCorrect;
					});
				}
			});
			return scoreLines;
		}
	}
}
</script>


<style scoped>
.axisWhite line {
	stroke: white;
}

.axisWhite path {
	stroke: white;
}

.axisWhite text {
	fill: white;
}
</style>
